<template>
    <NuxtLayout>
        <div class="container">
            <div class="row g-0 h-100 justify-content-center align-items-center">
                <div class="card border-0 shadow mt-5 text-bg-dark">
                    <img :src="images[error.statusCode]" class="card-img-top" />
                    <div class="card-body text-center">
                        <h1 class="card-title display-4">{{ $t('error') }}: {{ error.statusCode }}</h1>
                        <p class="card-text display-6">{{ $t(`error_message_${error.statusCode}`) }}</p>
                        <button type="button" class="btn btn-primary" @click="handleError">{{ $t('back_home') }}</button>
                    </div>
                </div>
            </div>
        </div>
    </NuxtLayout>
</template>

<script setup lang="ts">
import { filename } from 'pathe/utils'

const props = defineProps({
    error: Object
})

const glob = import.meta.glob('~/assets/img/error_images/*.gif', { eager: true })
const images = Object.fromEntries(
    Object.entries(glob).map(([key, value]) => [filename(key), value.default])
)
const handleError = () => clearError({ redirect: '/' })
</script>