import { library, config } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'

library.add(fab, fas, far)

config.autoAddCss = false

export default defineNuxtPlugin((nuxtApp: any) => {
    nuxtApp.vueApp.component('FontAwesomeIcon', FontAwesomeIcon)
})